// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Col from "../../../../../styleguide/components/Grid/Col/Col.res.js";
import * as Row from "../../../../../styleguide/components/Grid/Row/Row.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Grid from "../../../../../styleguide/components/Grid/Grid.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as Quill from "../../../../../bindings/Quill.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Editor from "../../../../../styleguide/forms/Editor/Editor.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Api_City from "../../../../../api/cities/Api_City.res.js";
import * as FormTags from "../../../../../styleguide/components/FormTags/FormTags.res.js";
import * as Keyboard from "../../../../../libs/Keyboard.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_City from "../../../../../routes/common/Routes_City.res.js";
import * as ErrorMessage from "../../../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as Routes_State from "../../../../../routes/common/Routes_State.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as CityEditFormScss from "./CityEditForm.scss";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var css = CityEditFormScss;

var validators_metaTitle = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaTitle.length;
      if (length > 200) {
        return {
                TAG: "Error",
                _0: "Meta title must no more than 200 characters."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.metaTitle
              };
      }
    })
};

var validators_metaDescription = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var length = input.metaDescription.length;
      if (length > 500) {
        return {
                TAG: "Error",
                _0: "Meta description must no more than 500 characters."
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.metaDescription
              };
      }
    })
};

var validators = {
  metaTitle: validators_metaTitle,
  metaDescription: validators_metaDescription,
  active: undefined,
  altNames: undefined
};

function initialFieldsStatuses(_input) {
  return {
          metaTitle: "Pristine",
          metaDescription: "Pristine",
          active: "Pristine",
          altNames: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            metaTitle: "Pristine",
            metaDescription: "Pristine",
            active: "Pristine",
            altNames: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.metaTitle;
  var tmp;
  tmp = typeof match !== "object" ? validators.metaTitle.validate(input) : match._0;
  var match$1 = fieldsStatuses.metaDescription;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.metaDescription.validate(input) : match$1._0;
  var match_0 = {
    TAG: "Ok",
    _0: input.active
  };
  var match_0$1 = {
    TAG: "Ok",
    _0: input.altNames
  };
  var metaTitleResult = tmp;
  var metaTitleResult$1;
  if (metaTitleResult.TAG === "Ok") {
    var metaDescriptionResult = tmp$1;
    if (metaDescriptionResult.TAG === "Ok") {
      var activeResult = match_0;
      if (activeResult.TAG === "Ok") {
        var altNamesResult = match_0$1;
        if (altNamesResult.TAG === "Ok") {
          return {
                  TAG: "Valid",
                  output: {
                    metaTitle: metaTitleResult._0,
                    metaDescription: metaDescriptionResult._0,
                    active: activeResult._0,
                    altNames: altNamesResult._0
                  },
                  fieldsStatuses: {
                    metaTitle: {
                      TAG: "Dirty",
                      _0: metaTitleResult,
                      _1: "Shown"
                    },
                    metaDescription: {
                      TAG: "Dirty",
                      _0: metaDescriptionResult,
                      _1: "Shown"
                    },
                    active: {
                      TAG: "Dirty",
                      _0: activeResult,
                      _1: "Hidden"
                    },
                    altNames: {
                      TAG: "Dirty",
                      _0: altNamesResult,
                      _1: "Hidden"
                    }
                  },
                  collectionsStatuses: undefined
                };
        }
        metaTitleResult$1 = metaTitleResult;
      } else {
        metaTitleResult$1 = metaTitleResult;
      }
    } else {
      metaTitleResult$1 = metaTitleResult;
    }
  } else {
    metaTitleResult$1 = metaTitleResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            metaTitle: {
              TAG: "Dirty",
              _0: metaTitleResult$1,
              _1: "Shown"
            },
            metaDescription: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            },
            active: {
              TAG: "Dirty",
              _0: match_0,
              _1: "Hidden"
            },
            altNames: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurMetaTitleField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaTitle, validators_metaTitle, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  metaTitle: status,
                                  metaDescription: init.metaDescription,
                                  active: init.active,
                                  altNames: init.altNames
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurMetaDescriptionField" :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.metaDescription, validators_metaDescription, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  metaTitle: init.metaTitle,
                                  metaDescription: status,
                                  active: init.active,
                                  altNames: init.altNames
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.active, state.fieldsStatuses.active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription,
                                  active: status,
                                  altNames: init.altNames
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurAltNamesField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.altNames, state.fieldsStatuses.altNames, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  metaTitle: init.metaTitle,
                                  metaDescription: init.metaDescription,
                                  active: init.active,
                                  altNames: status
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateMetaTitleField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.metaTitle, state.submissionStatus, validators_metaTitle, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            metaTitle: status,
                                            metaDescription: init.metaDescription,
                                            active: init.active,
                                            altNames: init.altNames
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateMetaDescriptionField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.metaDescription, state.submissionStatus, validators_metaDescription, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            metaTitle: init.metaTitle,
                                            metaDescription: status,
                                            active: init.active,
                                            altNames: init.altNames
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription,
                                            active: status,
                                            altNames: init.altNames
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateAltNamesField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.altNames, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            metaTitle: init.metaTitle,
                                            metaDescription: init.metaDescription,
                                            active: init.active,
                                            altNames: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                metaTitle: "Pristine",
                                metaDescription: "Pristine",
                                active: "Pristine",
                                altNames: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                metaTitle: "Pristine",
                                metaDescription: "Pristine",
                                active: "Pristine",
                                altNames: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateMetaTitle: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaTitleField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateMetaDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateMetaDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateAltNames: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateAltNamesField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurMetaTitle: (function () {
              dispatch("BlurMetaTitleField");
            }),
          blurMetaDescription: (function () {
              dispatch("BlurMetaDescriptionField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          blurAltNames: (function () {
              dispatch("BlurAltNamesField");
            }),
          metaTitleResult: Formality.exposeFieldResult(state.fieldsStatuses.metaTitle),
          metaDescriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.metaDescription),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          altNamesResult: Formality.exposeFieldResult(state.fieldsStatuses.altNames),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.metaTitle;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.metaDescription;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.active;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.altNames;
              if (typeof tmp$3 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var CityEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function reducer(state, action) {
  if (action.TAG === "SetEditorState") {
    return {
            editorState: Editor.State.compose(state.editorState, action._0),
            altName: state.altName
          };
  } else {
    return {
            editorState: state.editorState,
            altName: action._0
          };
  }
}

function CityEditForm$1(props) {
  var locality = props.locality;
  var initialState_editorState = Editor.State.make();
  var initialState = {
    editorState: initialState_editorState,
    altName: ""
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var initialInput = React.useMemo((function () {
          return {
                  metaTitle: locality.metaTitle,
                  metaDescription: locality.metaDescription,
                  active: locality.active,
                  altNames: locality.altNames
                };
        }), []);
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_City.update(locality.id, document.querySelector(".ql-editor").innerHTML, output.metaTitle, output.metaDescription, output.active, output.altNames), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_City.Dashboard.edit(x._0.city.id));
                  } else {
                    return SentryLogger.error1({
                                rootModule: "CityEditForm",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "CityEditForm.make"
                              }, "EditCountry::Error", [
                                "Error",
                                cb.notifyOnFailure()
                              ]);
                  }
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ErrorMessage.make, {
                  className: css.error,
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var containerRef = React.useRef(null);
  var editorRef = React.useRef(undefined);
  React.useEffect((function () {
          var editor = Belt_Option.getExn(Belt_Option.map(Caml_option.nullable_to_opt(containerRef.current), (function (container) {
                      return Quill.make("Type here...", undefined, undefined, Quill.countryToolbarOptions, {}, undefined, container);
                    })));
          editor.clipboard.dangerouslyPasteHTML(0, locality.description);
          var listener = function (delta, param) {
            dispatch({
                  TAG: "SetEditorState",
                  _0: delta
                });
          };
          editorRef.current = Caml_option.some(editor);
          editor.on("text-change", listener);
          return (function () {
                    editor.off("text-change", listener);
                  });
        }), []);
  var addAltName = function (altName) {
    var capitalized = Belt_Array.map(altName.split(" "), $$String.capitalize_ascii).join(" ");
    if (!form.input.altNames.includes(altName) && altName !== "") {
      form.input.altNames.push(capitalized);
      return form.updateAltNames((function (input, value) {
                    return {
                            metaTitle: input.metaTitle,
                            metaDescription: input.metaDescription,
                            active: input.active,
                            altNames: value
                          };
                  }), form.input.altNames);
    }
    
  };
  return JsxRuntime.jsx("form", {
              children: JsxRuntime.jsxs(Grid.make, {
                    className: css.grid,
                    children: [
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "country-form--active",
                                          children: "Active"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Toggle.make, {
                                            on: form.input.active,
                                            size: "MD",
                                            onChange: (function () {
                                                form.updateActive((function (input, value) {
                                                        return {
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: input.metaDescription,
                                                                active: value,
                                                                altNames: input.altNames
                                                              };
                                                      }), !form.input.active);
                                              })
                                          }),
                                      form.input.active ? JsxRuntime.jsx("div", {
                                              children: "This city currently has " + String(locality.locationsCountActive) + "\n                  active locations. Setting it to \"Inactive\" will remove it from\n                  public access.",
                                              className: css.fieldInstructions
                                            }) : JsxRuntime.jsx("div", {
                                              children: "This city currently has " + String(locality.locationsCountActive) + "\n                  active locations. Setting it to \"Active\" will make it public.",
                                              className: css.fieldInstructions
                                            })
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "city-form--city-description",
                                          children: "Description"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx(Editor.make, {
                                                ref: containerRef
                                              }),
                                          className: css.editor
                                        })
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "city-form--seo-title",
                                          children: "Meta Title"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "city-form--seo-title",
                                            value: form.input.metaTitle,
                                            placeholder: "Add a meta title.",
                                            onChange: (function ($$event) {
                                                form.updateMetaTitle((function (input, value) {
                                                        return {
                                                                metaTitle: value,
                                                                metaDescription: input.metaDescription,
                                                                active: input.active,
                                                                altNames: input.altNames
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaTitleResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "city-form--meta-description",
                                          children: "Meta Description"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(Textarea.make, {
                                            id: "city-form--meta-description",
                                            value: form.input.metaDescription,
                                            placeholder: "Add a meta description.",
                                            onChange: (function ($$event) {
                                                form.updateMetaDescription((function (input, value) {
                                                        return {
                                                                metaTitle: input.metaTitle,
                                                                metaDescription: value,
                                                                active: input.active,
                                                                altNames: input.altNames
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.metaDescriptionResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.labelContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx(Label.make, {
                                          forId: "city-form--alt-names",
                                          children: "Alternative Names"
                                        })
                                  }),
                              JsxRuntime.jsxs(Col.make, {
                                    className: css.fieldContainer,
                                    md: 9,
                                    lg: 9,
                                    children: [
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "city-form--alt-names",
                                            value: state.altName,
                                            placeholder: "Add a new name. Hit a comma to add the name.",
                                            onChange: (function ($$event) {
                                                var value = $$event.target.value.replace(new RegExp(","), "");
                                                dispatch({
                                                      TAG: "SetAltName",
                                                      _0: value
                                                    });
                                              }),
                                            onKeyUp: (function ($$event) {
                                                Keyboard.React.onComma($$event, (function () {
                                                        addAltName(state.altName);
                                                        dispatch({
                                                              TAG: "SetAltName",
                                                              _0: ""
                                                            });
                                                      }));
                                              })
                                          }),
                                      JsxRuntime.jsx(FormTags.make, {
                                            tags: form.input.altNames,
                                            updateTags: (function (altName) {
                                                return function (param) {
                                                  var altNames = Belt_Array.keep(form.input.altNames, (function (item) {
                                                          return item !== altName;
                                                        }));
                                                  form.updateAltNames((function (input, value) {
                                                          return {
                                                                  metaTitle: input.metaTitle,
                                                                  metaDescription: input.metaDescription,
                                                                  active: input.active,
                                                                  altNames: value
                                                                };
                                                        }), altNames);
                                                };
                                              })
                                          }),
                                      fieldError(form.altNamesResult)
                                    ]
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Row.make, {
                            className: css.row,
                            children: [
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonHiddenContainer,
                                    md: 3,
                                    lg: 3,
                                    children: JsxRuntime.jsx("div", {})
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCreateContainer,
                                    md: 4,
                                    lg: 4,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "Primary",
                                          expanded: true,
                                          disabled: form.submitting,
                                          busy: form.submitting,
                                          submit: true,
                                          children: "Save City"
                                        })
                                  }),
                              JsxRuntime.jsx(Col.make, {
                                    className: css.buttonCancelContainer,
                                    md: 5,
                                    lg: 5,
                                    children: JsxRuntime.jsx(Button.make, {
                                          size: "LG",
                                          color: "White",
                                          expanded: true,
                                          busy: false,
                                          submit: true,
                                          onClick: (function (e) {
                                              e.preventDefault();
                                              Url.visit(Routes_State.Dashboard.index);
                                            }),
                                          children: "Cancel"
                                        })
                                  })
                            ]
                          })
                    ]
                  }),
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var make = CityEditForm$1;

export {
  css ,
  CityEditForm ,
  reducer ,
  make ,
}
/* css Not a pure module */
