// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../styleguide/components/Heading/H1.res.js";
import * as H2 from "../../../../styleguide/components/Heading/H2.res.js";
import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as CityEditForm from "./components/CityEditForm.res.js";
import * as CityEditScss from "./CityEdit.scss";
import * as JsxRuntime from "react/jsx-runtime";
import * as CountriesDashboardNavbar from "../../../countries/dashboard/index/components/CountriesDashboardNavbar.res.js";

var css = CityEditScss;

function CityEdit$default(props) {
  var city = props.city;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(CountriesDashboardNavbar.make, {
                      currentLink: "Edit",
                      country: props.country,
                      state: props.state,
                      city: city
                    }),
                JsxRuntime.jsxs(Container.make, {
                      className: css.container,
                      children: [
                        JsxRuntime.jsx(H1.make, {
                              className: css.pageTitle,
                              children: "Edit City: " + city.name
                            }),
                        JsxRuntime.jsx(H2.make, {
                              className: css.pageSubTitle,
                              children: city.stateName + ", " + city.countryName
                            }),
                        JsxRuntime.jsx(CityEditForm.make, {
                              locality: city
                            })
                      ]
                    })
              ],
              className: css.pageContainer
            });
}

var $$default = CityEdit$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
